.ks-button {
    padding: 6px 12px;
    position: relative;
    transition: all 0.2s ease-in-out;
    display: flex;
    border: 1px solid transparent;
}

.ks-button:hover {
    box-shadow: var(--ks-box-shadow);
    position: relative;
}

.ks-button.large {
    padding: 8px 24px;
    min-width: 120px;
}

.ks-button.small {
    padding: 4px;
}

.ks-button.rounded {
    border-radius: 24px;
}

.ks-button.brand {
    background-color: var(--ks-brand-color);
    border: 1px solid var(--ks-brand-color-dark);
    box-shadow: var(--ks-box-shadow-shallow);
}

.ks-button.transparent {
    background-color: #00000080;
    color: white;
}

.ks-button.transparent:hover {
    background-color: black;
}

.ks-button.brand:hover {
    background-color: var(--ks-brand-color-hover);
}


.ks-button.secondary {
    border: 1px solid var(--ks-divider);
    box-shadow: var(--ks-box-shadow-shallow);
    background-color: white;
}

.ks-button.brand:disabled {
    background-color: var(--ks-divider-light);
    color: var(--ks-blue-dark);
    border: 1px solid transparent;
}

.ks-button.brand-secondary {
    background-color: var(--ks-brand-color-light);
}

.ks-button.on-brand:disabled {
    color: #00000060;
    background-color: #FFFFFF40;
}

.ks-button.on-brand {
    background-color: #FFFFFF;
}

#keystone-tray .ks-button.on-brand {
    background-color: #FFFFFF40;
}

.ks-button.loading p {
    opacity: 0;
}

.ks-button.loading {
    background-color: var(--ks-divider-superlight);
    border: 1px solid var(--ks-divider-transparent-light);
}

.ks-button.alternate {
    background-color: var(--ks-divider-light);
    border: 1px solid var(--ks-divider-transparent-light);
}

.ks-button.transparent.alternate {
    background-color: #00000020;
    border: 1px solid var(--ks-divider-transparent-light);
}

.ks-button.on-brand.secondary {
    background-color: #ffffff60;
}
    
@media only screen and (max-width: 767px) {
}