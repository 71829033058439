.keystone-label {
    margin: unset;
}

.keystone-label.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.keystone-label em {
    font-style: normal;
    color: black;
}

.keystone-label.lazy {
    color: rgba(0, 0, 0, 0);
}

.keystone-label.single-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

.keystone-label.shake-once {
    animation: shakeAnimation 0.2s ease-in-out 2;
}

@keyframes shakeAnimation {
    0% {
        transform: translateX(0);
    }
    20% {
        transform: translateX(-5px);
    }
    40% {
        transform: translateX(4px);
    }
    60% {
        transform: translateX(-3px);
    }
    80% {
        transform: translateX(2px);
    }
    100% {
        transform: translateX(0);
    }
}

#list-bullet {
    padding-left: 10px;
    font-size: 1.5em;
    vertical-align: middle;
}

.keystone-label.negative {
    color: var(--ks-negative-text);
}

.keystone-label.positive {
    color: var(--ks-positive-text);
}

.keystone-label.secondary.invert {
    color: #1b2549;
}

.keystone-label.capitalize {
    text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
}