:root {
  --white: white;
  --black: black;
  --black-8: rgba(0, 0, 0, 0.08);
  --black-60: rgba(0, 0, 0, 0.6);
  --blue-black-4:rgb(248, 249, 251);
  --blue-black-8: rgb(241, 242, 246);
  --blue-black-16: rgb(222, 224, 232);
  --text-brand: rgb(25, 27, 28);
  --text-brand-light: rgb(115, 127, 133);
  --text-brand-disabled: rgb(133, 145, 149);
  --orange-lighter: #f7f4f0;
  --orange-light: #e68e00;
  --orange-dark: #f0592c;
  --orange-border: #a5530c;
  --green-light: #2dcfa9;
  --green-dark: #21ac5d;
  --green-border: #1f785c;
  --yellow-light: #edc511;
  --yellow-dark: #f0b22c;
  --yellow-border: #b87f15;
  --red-light: #ef4a28;
  --red-dark: #c52525;
  --red-border: #a32424;
  --blue-light: #a9bee6;
  --blue-dark: #706ada;
  --background: #f6f6f6;
  --yellow: rgb(255, 221, 87);
  --grey: #f4f6fd;
}

.yellow-bg {
  background-color: var(--yellow);
}

.grey-bg {
  background-color: var(--grey);
}

.white-bg {
  background-color: var(--white);
}

.green-bg {
  background-color: var(--ks-positive-background);
}

.green-text {
  color: var(--ks-positive-text);
}

.red-bg {
  background-color: var(--ks-negative-background);
}

.red-text {
  color: var(--ks-negative-text);
}

html, body, #root, #app {
  height: -webkit-fill-available;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  background-color: var(--background);
}

.loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 6rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  animation: pulsOut 1.8s ease-in-out infinite;
  filter: drop-shadow(0 0 1rem rgba(179, 185, 207, 0.75));
}
.loader:before {
  width: 100%;
  padding-bottom: 100%;
  box-shadow: inset 0 0 0 1rem rgb(179, 185, 207);
  animation-name: pulsIn;
}
.loader:after {
  width: calc(100% - 2rem);
  padding-bottom: calc(100% - 2rem);
  box-shadow: 0 0 0 0 #B3B9CF;
}

@keyframes pulsIn {
  0% {
    box-shadow: inset 0 0 0 1rem #B3B9CF;
    opacity: 1;
  }
  50%, 100% {
    box-shadow: inset 0 0 0 0 #B3B9CF;
    opacity: 0;
  }
}

@keyframes pulsOut {
  0%, 50% {
    box-shadow: 0 0 0 0 #B3B9CF;
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 1rem #B3B9CF;
    opacity: 1;
  }
}
    
    

#loader-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: pulse 3s infinite ease-in-out;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#app.sidebar {
  align-items: flex-start;
}

#app.pdf-view {
  background-color: var(--white);
  height: initial;
}

p, button, input, td {
  font-family: "Poppins", sans-serif;
}

button {
  transition: all 0.1s ease-in-out;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  box-shadow: 0 4px 12px var(--black-8);
  background: linear-gradient(to bottom, var(--orange-light), var(--orange-dark));
  border: 1px solid var(--orange-border);
  min-width: 120px;
  border-radius: 32px;
  line-height: 22px;
  box-sizing: border-box;
}

p {
  padding: 0;
  margin: 0;
}

ul {
  margin: 0;
}

#logo-new {
  height: 32px;
}

#logo-new.sidebar {
  margin-top: 24px;
}

#header {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  box-shadow: 0 4px 12px var(--black-8);
  z-index: 2;
  background-color: var(--white);
}

#header.sidebar {
  width: 20%;
  width: 320px;
  background-color: initial;
  box-shadow: initial;
  z-index: 1;
  height: 100vh;
  flex-direction: column;
  align-items: flex-start;
  pointer-events: none;
  padding: 32px 32px 24px 32px;
  justify-content: flex-end;
}

.label {
  color: var(--text-brand);
  font-size: 16px;
  font-weight: 600;
}


.content-label {
  color: var(--text-brand);
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  padding-bottom: 2px;
}

.label.small {
  font-size: 14px;
}

select {
  background: url("./media/caret-down-24@3x.png") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 12px) center;
  background-repeat: no-repeat;
  background-color: #efefef;  -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none;
  padding: 8px 0 8px 8px;
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-brand);
  cursor: pointer;
  outline: none;
  width: 100%;
}

.card {
  display: flex;
  background-color: var(--white);
  box-shadow: 0 4px 12px var(--black-8);
  padding: 16px;
  border-radius: 12px;
  row-gap: 12px;
  flex-direction: column;
}

.vertical-card-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex: 1;
}

.horizontal-card-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  flex: 1;
}

.ignore-click {
  pointer-events: none;
}

.horizontal {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.vertical {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.centered {
  align-items: center;
  justify-content: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 132px; /* Adjusted width for text */
  height: 36px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.toggle-button-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--blue-black-8);
  -webkit-transition: .4s;
  transition: .4s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px; /* Provide some padding for the text */
  border-radius: 6px;
}

.toggle-button-slider:before {
  content: "";
  height: 28px;
  width: 58px;
  position: absolute;
  bottom: 4px;
  background: var(--white); 
  -webkit-transition: .4s;
  transition: .4s;
  z-index: 2;
  left: 4px; /* Adjust if needed */
  border-radius: 4px;
  box-shadow: 0 4px 12px var(--black-8);
}

input:checked + .toggle-button-slider:before {
  -webkit-transform: translateX(66px); /* Adjust distance */
  -ms-transform: translateX(66px);
  transform: translateX(66px);
}

/* Styling texts inside the slider */
.toggle-button-slider .text {
  color: var(--text-brand-light);
  font-size: 14px;
  z-index: 1;
  width: 50%;
  text-align: center;
  position: relative;
  z-index: 2;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.toggle-button-slider .text.active {
  color: var(--text-brand);
}

.divider {
  flex: 1;
  display: flex;
  min-height: 1px;
  max-height: 1px;
  background-color: var(--blue-black-8);
  width: 100%;
}

.info {
  stroke: var(--text-brand-light);
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: var(--blue-black-4);
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: linear-gradient(to bottom, var(--orange-light), var(--orange-dark));
  border: 1px solid var(--orange-border);
  border-radius: 100%;
  cursor: pointer;
}

h3, h2 {
  margin: 0;
  color: var(--text-brand);
  font-weight: 600;
}

h2 {
  font-size: 24px;
}

.vertical-flex-1 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

.m-t-2 {
  margin-top: 16px;
}

.end {
  justify-content: flex-end;
}

#pdf-view {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
}

.row-gap-12 {
  row-gap: 12px;
}

.p-top-16 {
  margin-top: 16px;
}

.gravity-left {
  justify-content: flex-start;
}

.invalid {
  border: 1px solid var(--orange-dark) !important;
}

.mw-90 {
  max-width: 90%;
}

.top {
  align-items: flex-start;
}

.m-r-12 {
  margin-right: 12px;
}

.space-between {
  justify-content: space-between;
}

.bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-12 {
  margin-bottom: 12px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.m-b-48 {
  margin-bottom: 48px;
}

.m-b-64 {
  margin-bottom: 64px;
}

.content {
  font-size: 10px;
  line-height: 14px;
  padding-bottom: 4px;
}

.italics {
  font-style: italic;
}

.p-r-8 {
  padding-right: 8px;
}

.p-l-8 {
  padding-left: 8px;
}

.no-min-width {
  min-width: 0 !important;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media print {
  html, body, #root, #app {
    background: var(--white);
  }

  #root {
    height: initial;
  }

  .vertical {
    display: initial;
  }
}