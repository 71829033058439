.keystone-upload-flow {
    overflow: visible;
}

.upload-promo-container {
    max-width: 820px;
}


.product-card-blue {
    /* background-color: var(--ks-mint);
    box-shadow: var(--ks-box-shadow);
    border: 1px solid var(--ks-divider-dark); */
    border-radius: 12px;
    padding: 12px;
    background: var(--blue);
}

.keystone-upload-flow .keystone-divider p {
    background-color: var(--ks-divider-superlight);
}

#close-button { 
    top: 40px;
    right: 40px;
    position: absolute;
    border-radius: 36px;
    padding: 12px;
}

#value-props-wrapper {
    padding: 0 24px;
}

#welcome-logo {
    position: absolute;
    top: 24px;
    right: 24px;
    border-radius: 100%;
}

#welcome-logo img {
    border-radius: 100%;
    width: 40px;
    height: 40px;
}

.product-card-blue p.secondary {
    color: #1b2549 !important;
}

#welcome-overlay {
    max-height: 100%;
    justify-content: flex-start;
    flex-direction: column;
}

@media only screen and (max-width: 767px) {
    #welcome-header {
        text-align: left;
        align-items: flex-start;
    }

    #welcome-logo img {
        border-radius: 100%;
        width: 32px;
        height: 32px;
    }

    .upload-promo-container {
        padding: 0;
        padding-bottom: 32px;
    }

    #welcome-logo {
        top: 16px;
        right: 16px;
    }

    .product-card-blue {
        box-shadow: none;
        border: none;
    }

    #keystone-search-part {
        width: 100%;
    }
}