.keystone-menu-button:hover {
    background-color: var(--ks-divider);
    border-radius: 8px;
}

.keystone-menu-button.active:hover {
    background-color: white;
}

@media only screen and (max-width: 767px) {
    .keystone-menu-button.active:hover {
        background-color: transparent;
    }
}