#keystone-toasts-wrapper {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 12px;
    right: 0;
    bottom: 0;
    width: 100%;
    pointer-events: none;
    z-index: 100;
    display: flex;
    justify-content: center;
}

 #keystone-user-toasts-wrapper {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 12px;
    right: 0;
    bottom: 24px;
    width: 100%;
    pointer-events: none;
    z-index: 100;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
