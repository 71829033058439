#keystone-create-account {
    display: flex;
    flex: 1;
    max-width: 960px;
}

#keystone-create-account.invite {
    max-width: 420px;
}

#create-account::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(246, 246, 246, 0) 10%, rgba(246, 246, 246, 1) 100%);
    pointer-events: none;  /* Make sure this overlay doesn't affect interaction with the underlying content */
    z-index: 1;
}

#create-account-details-pane {
    /* background: linear-gradient(-135deg, var(--ks-brand-color) 0%, var(--ks-brand-color-dark) 100%); */
    background-color: var(--ks-brand-color);
    box-sizing: border-box;
    padding: 48px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

#create-account-content-wrapper {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: 0 12px 48px rgba(0,0,0,0.08);
    overflow: hidden;
}

#create-account-content-wrapper.invite {
    border-radius: 12px;
}

.create-account-header-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    z-index: 2;
}
  
#splash-footer p {    
    margin: 0;
}

#create-account-privacy-anchor {
    margin-top: 16px;
    font-weight: 500;
}

#create-account-title {
    font-weight: 600;
    color: white;
}

#create-account-description {
    color: rgba(255,255,255,0.9);
    font-weight: 500;
    line-height: 20px;
}

#create-account-details-wrapper {
    padding: 32px 32px 32px 32px;
    background-color: white;
}

.create-account-button, .invite-code-button {
    display: flex;
    background: white;
    border: 1px solid rgba(0,0,0,0.16);
    border-radius: 6px;
    color: black;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 8px rgba(0,0,0,0.04);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    justify-content: center;
}

.create-account-button:disabled, .create-account-button:disabled:hover {
    background: var(--ks-divider-light);
    border: 1px solid var(--ks-divider-light);
    box-shadow: none;
    color: var(--ks-blue-dark);
    cursor: not-allowed;
}

.invite-code-button {
    padding: 6px 8px;
}

.invite-code-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.create-account-button {
    width: 100%;
    padding: 0 14px;
    height: 42px;
}

.create-account-button.with-logo {
    justify-content: space-between;
}

.create-account-button:hover, .invite-code-button:hover {
    box-shadow: 0 6px 12px rgba(0,0,0,0.08);
    position: relative;
}

.create-account-button img:first-child, .create-account-button img:last-child {
    width: 24px;
}

.create-account-button img:last-child {
    opacity: 0;
}

#create-account-header-wrapper {
    /* background: linear-gradient(25deg, black 0%, rgba(0, 0, 0, 0.0) 100%); */
    background-color: black;
    padding: 36px;
}

#create-account-details {
    margin-top: 12px;
}

#create-account-details, #create-account-redirect {
    color: #758A91;
    font-weight: 500;
    line-height: 18px;
}

#create-account-footer-wrapper {
    background: #faf8f823;
    backdrop-filter: blur(4px);
    padding: 20px 16px;
    border-top: 1px solid rgba(0,0,0,0.08);
    text-align: center;
}

#create-account-footer-wrapper a, #create-account-footer-wrapper a:visited {
    color: black;
}

#create-account-footer-wrapper.cta {
    background: #FFCC16;
}

#create-account-footer-wrapper.cta p {
    color: black;
}
    
@media only screen and (max-width: 767px) {
}