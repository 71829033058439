.keystone-row {
    display: flex;
}

.keystone-row.clickable {
    cursor: pointer;
}

.keystone-row.clickable:hover {
    background-color: var(--ks-divider-superlight);
}

@media only screen and (max-width: 767px) {
}