#keystone-menu {
    display: flex;
    padding: 24px 0 24px 24px;
    flex-direction: column;
    box-sizing: border-box;
    flex-basis: 20%;
    max-width: 320px;
}

#menu-navigation {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
}

#menu-company {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

#company-logo {
    width: 36px;
    height: 36px;
    background-color: white;
    padding: 2px;
    border-radius: 32px;
    box-shadow: 0 12px 32px #E6E8F0;
}

#menu-user-avatar {
    width: 36px;
    height: 36px;
    background-color: white;
    padding: 2px;
    border-radius: 32px;
    box-shadow: 0 12px 32px #E6E8F0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1 0 auto;
}

#user-initials {
    display: flex;
    text-align: center;
}

#company-name {
    font-weight: 600;
}

#menu-content {
    display: flex;
    flex: 1;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    row-gap: 12px;
}

.content-row {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 8px;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.open .content-row {
    width: 70%;
    padding: 8px;
}

.content-row.active {
    background: white;
    box-shadow: 0 12px 32px #E6E8F0;
}

.row-icon{
    width: 24px;
    height: 24px;
}

.row-label {
    padding-left: 8px;
    color: #8389A0;
    font-weight: 500;
}

.content-row.active .row-icon {
   filter: brightness(0%);
}

.content-row.active .row-label {
    color: black;
}

#menu-collapse {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 12px 32px #E6E8F0;
    display: flex;
    padding: 8px;
    align-items: center;
    height: 40px;
}

#menu-collapse-icon {
    width: 24px;
    height: 24px;
}

#keystone-menu.open #menu-collapse {
    background-color: transparent;
    box-shadow: none;
}

#menu-footer {
    display: flex;
    justify-content: space-around;
    justify-content: flex-end;
}

#keystone-menu.open #menu-footer {
    align-items: center;
    justify-content: space-between;
}

#menu-user {
    display: flex;
    flex-direction: column;
    display: none;
    min-width: 0;
}

#user-name {
    font-weight: 600;
    line-height: 16px;
}

#user-title {
    font-weight: 400;
    line-height: 16px;
}

#menu-company {
    display: none;
}

#keystone-menu.open #menu-user {
    display: flex;
}

#keystone-menu.open #menu-company {
    display: flex;
}

#menu-user-avatar:last-of-type {
    display: none;
}

#keystone-menu.open #menu-user-avatar:last-of-type {
    display: none;
}

#mobile-page-title {
    display: none;
}

#create-account-button {
    padding: 8px 24px;
    height: 40px;
}

#create-account-button.menu-icon-button {
    padding: 0;
    width: 40px;
    height: 40px;
}

@media only screen and (max-width: 767px) {
    #mobile-page-title {
        display: initial;
        text-align: center;
    }

    #desktop-page-title {
        display: none;
    }

    #keystone-menu, #menu-content {
        flex-direction: row;
        max-width: initial;
    }

    .row-icon {
        width: 24px;
        height: 24px;
    }

    #menu {
        width: 18px;
        height: 18px;
        padding: 12px;
        border-radius: 12px;
    }

    .content-row {
        border-radius: 4px;
        padding: 12px;
        justify-content: center;
    }

    #keystone-menu {
        padding: 16px;
    }

    #keystone-menu:first-of-type {
        padding: 0;
        position: absolute;
        bottom: 0;
        height: 64px;
        width: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        padding: 8px;
    }

    #keystone-menu #menu-content {
        background-color: #ffffff;
        box-shadow: var(--ks-box-shadow);
        border-radius: 8px;
    }

    .row-label {
        display: none;
    }

    .open .content-row {
        width: initial;
        flex: 1;
    }

    #keystone-menu:first-of-type #menu-company {
        display: none;
    }

    #menu-user, #company-name {
        display: none;
    }

    #keystone-menu:first-of-type #menu-navigation {
        display: none;
    }

    #keystone-menu:first-of-type #menu-footer {
        display: none;
    }

    #keystone-menu:last-of-type  #menu-content {
        display: none;
    }

    #keystone-menu:last-of-type #menu-collapse {
        display: none;
    }

    #keystone-menu:last-of-type #menu-company {
        display: flex;
    }

    #keystone-menu.open #menu-user {
        display: none;
    }

    #logout-overflow-menu {
        display: none;
    }

    #menu-company p {
        display: none;
    }

    .content-row.active {
        background: transparent;
        box-shadow: none;
    }
}