.keystone-cell-preview {
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cell-preview-column-name-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid var(--ks-divider-dark);
    width: 100%;
    border-top: 1px solid var(--ks-divider-dark);
}

.cell-preview-column-name-wrapper.last {
    border-right: 1px solid var(--ks-divider-dark);
    border-top-right-radius: 8px;
}

.cell-preview-column-name-wrapper.first {
    border-top-left-radius: 8px;
}

.keystone-cell-preview.primary {
    flex: 2 1 0;
}

.cell-preview-message {
    margin: 8px;
    border-radius: 24px;
}

.cell-preview-value {
    line-height: 24px;
    min-width: 32px;
}

.cell-preview-column-name-wrapper.last {
    border-right: 1px solid #cccfdd;
}

.cell-preview-value-wrapper.last {
    border-right: 1px solid #cccfdd;
}

.cell-preview-value-wrapper {
    border-left: 1px solid #cccfdd;
    border-top: 1px solid #cccfdd;
    border-bottom: 1px solid #cccfdd;
}

.cell-preview-value-wrapper.last {
    border-bottom-right-radius: 8px;
}
.cell-preview-value-wrapper.first {
    border-bottom-left-radius: 8px;
}

.cell-preview-value-wrapper.primary {
    background-color: white;
}

@media only screen and (max-width: 767px) {
}