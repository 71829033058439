.keystone-icon-wrapper {
   mask-size: cover;
   -webkit-mask-size: cover;
   mask-repeat: no-repeat;
   -webkit-mask-repeat: no-repeat;
   mask-position: center;
   -webkit-mask-position: center;
   display: inline-block;
   transition: all 0.24s ease-in-out;
}

.invert-icon {
   mix-blend-mode: difference;
}

@media only screen and (max-width: 767px) {
}