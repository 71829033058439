.keystone-score-bar {
   padding: 4px; /* Optional */
}
 
.bar-background {
   width: 100%;
   background-color: #eee;
   border-radius: 4px;
   overflow: hidden;
   height: 18px; /* Adjust height as needed */
}

.bar-fill {
   height: 100%;
   width: 0; /* Will animate to full width */
   background-color: black;
   transition: width 1s ease, background-color 1s ease; /* Smooth animation */
}

.score-bar-label {
   position: absolute;
   width: 100%;
   text-align: center;
   color: white;
   mix-blend-mode: difference;
}

.score-bar-label-min {
   position: absolute;
   color: white;
   mix-blend-mode: difference;
   z-index: 2;
   left: 4px;
}

.score-bar-label-max {
   position: absolute;
   color: white;
   mix-blend-mode: difference;
   z-index: 2;
   right: 4px;
}
    
@media only screen and (max-width: 767px) {
}