.keystone-select {
    width: initial;
    background-color: white;
    border: 1px solid var(--ks-divider);
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
}

@media only screen and (max-width: 767px) {
}