#keystone-tray {
    max-height: -webkit-fill-available;
}


.offer-total-wrapper {
    background-color: black;
    border-radius: 6px;
}

#keystone-tray.over-everything {
    z-index: 3;
}

@media only screen and (max-width: 767px) {
    #keystone-tray {
        box-shadow: none;
    }
}