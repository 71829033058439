
div[data-viewport-type="element"] .list-container {
    padding: 16px;
}

.inventory-column.strict-width {
    flex-basis: 200px;
    flex-grow: 0;
    flex-shrink: 1;
}

.inventory-column.relaxed-width {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 3;
}

.inventory-column {
    padding: 0 24px;
}

.inventory-column.first {
    padding: 0 24px 0 0;
}

.inventory-column.last {
    padding: 0 0 0 24px;
}

.inventory-column-1 {
    padding: 0 24px 0 0;
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 3;
}

.inventory-column-2 {
    flex-basis: 200px;
    flex-grow: 0;
    flex-shrink: 1;
    padding: 0 24px;
}

.inventory-column-3 {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 3;
    padding: 0 24px;
}

.inventory-column-4 {
    flex-basis: 200px;
    flex-grow: 0;
    flex-shrink: 1;
    padding: 0 0 0 24px;
}

#keystone-inventory-table-wrapper {
    display: flex;
}

#keystone-inventory-table {
    box-shadow: none;
    width: 100%;
    position: relative;
}

#keystone-inventory-table thead {
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: 0 4px 12px #E6E8F0;
}

#keystone-inventory-table th {
    border: none;
}

#keystone-inventory-table tfoot {
    position: sticky;
    bottom: 0;
    background-color: white;
    box-shadow: 0 -4px 12px #E6E8F0;
}

#keystone-inventory-header-wrapper {
    padding: 16px 16px 0 16px;
    position: sticky;
    top: 0;
    z-index: 2;
    background: linear-gradient(to bottom, #FFFFFF, #FFFFFF00);
    backdrop-filter: blur(8px);
}

#keystone-inventory-header {
    background-color: white;
    padding: 8px 12px;
    font-weight: 600;
    box-shadow: var(--ks-box-shadow);
    border: 1px solid var(--ks-divider);
    border-radius: 4px;
}

@media only screen and (max-width: 767px) {
    #keystone-inventory-header-wrapper {
        padding: 0;
    }

    #keystone-inventory-header {
        border-left: none;
        border-right: none;
        border-radius: 0;
    }

    #list-row-container {
        padding-bottom: 58px;
    }
}