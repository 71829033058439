#keystone-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 3;
    transition: all 0.3s ease-in-out;
    overflow: auto;
}

#keystone-modal.minus-480 {
    width: calc(100% - 480px);
}

#keystone-modal.blur {
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
}

.modal-wrapper {
    box-shadow: 0 0 32px #E6E8F0;
    background-color: var(--ks-divider-superlight);
    border: 1px solid var(--ks-divider-transparent-light);
    border-radius: 12px;
    max-width: 640px;
    min-width: 520px;
    overflow: hidden;
}

.modal-width {
    max-width: 640px;
    min-width: 520px;
}

.modal-wrapper.cta {
    background-color: var(--ks-brand-color);
}

.modal-header {
    background-color: white;
    box-shadow: var(--ks-box-shadow-shallow);
    border-bottom: 1px solid var(--ks-divider-transparent-light);
}
.modal-header-button {
    font-family: "SF Pro";
    font-weight: bold;
    padding: 2px 12px;
}

.modal-header-button.active {
    color: #ff8400;
    background: #fff4df;
}

.modal-disclaimer {
    margin-bottom: 12px;
    line-height: 20px;
}

.cta-bg {
    background-color: var(--ks-brand-color);
}

.cta-bg-secondary {
    background-color: var(--ks-divider-light);
}

.drag-drop-text {
    color: black;
}

.cell-preview-row-value {
    border-top: 1px solid #cccfdd;
    border-bottom: 1px solid #cccfdd;
}

@media only screen and (max-width: 767px) {
    .modal-wrapper, .modal-width {
        min-width: 96%;
        max-width: 96%;
    }
}