.location-summary {
    flex-basis: 230px;
    flex-grow: 0;
    flex-shrink: 0;
}

.location-address {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
}