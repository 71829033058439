.keystone-inventory-header-column {
   display: flex;
   cursor: pointer;
   border-right : 1px solid var(--ks-divider);
}

.keystone-inventory-header-column:last-child {
   border-right: none;
}

.keystone-column-header {

}

.keystone-inventory-header-column button {
   padding: 0;
   display: flex;
   transition: all 0.24s ease-in-out;
}

.keystone-inventory-header-column button.rotate {
   transform: rotate(180deg);
}

.keystone-inventory-header-column button.disabled {
   /* opacity: 0.5; */
}

.flex-0 {
   flex: 0;
}

.visibility-icon.hidden {
   opacity: 0;
}

#keystone-inventory-table .keystone-info-block.hide {
   max-width: 64px;
   max-height: 0px;
   min-width: initial
}

.keystone-inventory-header-column.placeholder-width {
   max-width: 64px;
   min-width: initial
}

@media only screen and (max-width: 767px) {
}