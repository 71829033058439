.keystone-chip-rounded {
   background-color: black;
   color: white;
   display: flex;
   border-radius: 4px;
   align-items: center;
}

.keystone-chip-rounded.secondary {
   background-color: var(--ks-blue-dark);
}

.keystone-chip-rounded p {
   padding: 4px 6px;
}

.keystone-chip-rounded.positive {
   color: var(--ks-positive-text);
   background-color: var(--ks-positive-background);
}

.keystone-chip-rounded.small p {
   padding: 1px 4px 2px 4px;
}


.keystone-chip-rounded.negative {
   color: var(--ks-negative-text);
   background-color: var(--ks-negative-background);
}

@media only screen and (max-width: 767px) {
}