@font-face {
    font-family: 'SF Mono';
    src: url('../../assets/fonts/SF-Mono-Regular.otf') format('opentype');
    font-weight: normal; /* Specify the weight of the font; Change if necessary */
    font-style: normal;  /* Specify the style of the font; Change if necessary */
}

@font-face {
    font-family: 'SF Mono';
    src: url('../../assets/fonts/SF-Mono-Medium.otf') format('opentype');
    font-weight: 500; /* Specify the weight of the font; Change if necessary */
    font-style: medium;  /* Specify the style of the font; Change if necessary */
}

@font-face {
    font-family: 'SF Mono';
    src: url('../../assets/fonts/SF-Mono-Bold.otf') format('opentype');
    font-weight: bold; /* Specify the weight of the font; Change if necessary */
    font-style: bold;  /* Specify the style of the font; Change if necessary */
}

@font-face {
    font-family: 'SF Pro';
    src: url('../../assets/fonts/SF-Pro-Text-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro';
    src: url('../../assets/fonts/SF-Pro-Text-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: bold;
}

*, *::before, *::after {
    box-sizing: border-box;
}

:root {
    --ks-brand-color: #FFCC16;
    --ks-brand-color-hover: #ffd746;
    --ks-brand-color-light: #fcefc0;
    --ks-brand-color-dark: #e09f07;
    --ks-divider: #E6E8F0;
    --ks-divider-transparent: rgba(0,0,0,0.24);
    --ks-divider-transparent-light: rgba(0,0,0,0.12);
    --ks-divider-light: #f2f4f8;
    --ks-divider-superlight: #f6f8fb;
    --ks-blue: #DDECEC;
    --ks-mint: #E6F4F1;
    --ks-divider-dark: #b9bfd6;
    --ks-positive: #079f80;
    --ks-positive-text: rgb(38, 92, 38);
    --ks-positive-background: rgb(196, 236, 196);
    --ks-negative-text: #b60b0b;
    --ks-negative-background: #f7e0e2;
    --ks-negative: #e64040;
    --ks-brand-secondary: #372d5b;
    --ks-blue-dark: #6f7aa5;
    --ks-box-shadow: 0 12px 32px rgba(230, 232, 240, 0.5);
    --ks-box-shadow-up: 0 -12px 32px rgba(230, 232, 240, 0.5);
    --ks-box-shadow-gray: 0 12px 24px rgba(0, 0, 0, 0.04);
    --ks-box-shadow-shallow: 0 2px 8px rgba(230, 232, 240, 0.5);
    --ks-text-color: #333;
    --ks-negative-color: #e64040;
    --ks-dark-blue: #1c6fff;
    --ks-green: #00b894;
    --blue: rgb(155, 176, 255);
    --yellow: rgb(255, 221, 87);
    --ks-primary-gradient: linear-gradient(      /* Gradient for over 50% */
        to top,
        #6c9cef,
        #0857de,
        #ffffff
    );
    --ks-under-gradient: linear-gradient(        /* Gradient for under 50% */
        to top,
        #ff6666,
        #ff9999,
        #ffffff
    );
    --ks-center-line-color: #003D33;             /* Darker line for contrast */
    --ks-divider: #E6E8F0;                       /* Keeping the original */
}

a.visited-black:visited {
    color: black;
}

.blue-bg {
    background-color: var(--blue);
}

.black-bg {
    background-color: black;
}

.hide {
    opacity: 0;
}

/* SEMANTICS */

.size-xs {
    font-size: 10px;
    line-height: 12px;
}

.size-s {
    font-size: 12px;
    line-height: 14px;
}

.line-s {
    line-height: 16px;
}

.size-m {
    font-size: 14px;
    line-height: 16px;
}

.line-m {
    line-height: 20px;
}

.size-l {
    font-size: 16px;
    line-height: 18px;
}

.size-xl {
    font-size: 18px;
    line-height: 20px;
}

.line-xl{
    line-height: 32px;
}

.size-display {
    font-size: 24px;
    line-height: 26px;
}

.pad-xxs {
    padding: 4px;
}
.pad-b-xxs {
    padding-bottom: 4px;
}
.pad-l-xxs {
    padding-left: 4px;
}
.pad-r-xxs {
    padding-right: 4px;
}
.pad-t-xxs {
    padding-top: 4px;
}

.pad-xs {
    padding: 8px;
}
.pad-b-xs {
    padding-bottom: 8px;
}
.pad-l-xs {
    padding-left: 8px;
}
.pad-r-xs {
    padding-right: 8px;
}
.pad-t-xs {
    padding-top: 8px;
}

.pad-s {
    padding: 12px;
}
.pad-b-s {
    padding-bottom: 12px;
}
.pad-l-s {
    padding-left: 12px;
}
.pad-r-s {
    padding-right: 12px;
}
.pad-t-s {
    padding-top: 12px;
}


.pad-m {
    padding: 16px;
}
.pad-b-m {
    padding-bottom: 16px;
}
.pad-l-m {
    padding-left: 16px;
}
.pad-r-m {
    padding-right: 16px;
}
.pad-t-m {
    padding-top: 16px;
}


.pad-l {
    padding: 24px;
}
.pad-b-l {
    padding-bottom: 24px;
}
.pad-l-l {
    padding-left: 24px;
}
.pad-r-l {
    padding-right: 24px;
}
.pad-t-l {
    padding-top: 24px;
}


.pad-xl {
    padding: 32px;
}
.pad-b-xl {
    padding-bottom: 32px;
}
.pad-l-xl {
    padding-left: 32px;
}
.pad-r-xl {
    padding-right: 32px;
}
.pad-t-xl {
    padding-top: 32px;
}


.pad-h-xs {
    padding-left: 8px;
    padding-right: 8px;
}
.pad-v-xs {
    padding-top: 8px;
    padding-bottom: 8px;
}

.pad-h-s {
    padding-left: 12px;
    padding-right: 12px;
}
.pad-v-s {
    padding-top: 12px;
    padding-bottom: 12px;
}

.pad-h-m {
    padding-left: 16px;
    padding-right: 16px;
}
.pad-v-m {
    padding-top: 16px;
    padding-bottom: 16px;
}

.pad-h-l {
    padding-left: 24px;
    padding-right: 24px;
}
.pad-v-l {
    padding-top: 24px;
    padding-bottom: 24px;
}

.pad-h-xl {
    padding-left: 32px;
    padding-right: 32px;
}
.pad-v-xl {
    padding-top: 32px;
    padding-bottom: 32px;
}

.v-gap-xxs {
    row-gap: 2px;
}

.v-gap-xs {
    row-gap: 4px;
}

.v-gap-s {
    row-gap: 8px;
}

.v-gap-m {
    row-gap: 12px;
}

.v-gap-l {
    row-gap: 16px;
}

.v-gap-xl {
    row-gap: 24px;
}

.v-gap-xxl {
    row-gap: 32px;
}

.v-gap-display {
    row-gap: 48px;
}

.h-gap-xxs {
    column-gap: 2px;
}

.h-gap-xs {
    column-gap: 4px;
}

.h-gap-s {
    column-gap: 8px;
}

.h-gap-m {
    column-gap: 12px;
}

.h-gap-l {
    column-gap: 16px;
}

.h-gap-xl {
    column-gap: 24px;
}

.h-gap-xxl {
    column-gap: 32px;
}

.h-gap-display {
    column-gap: 48px;
}

.min-h-l {
    min-height: 248px;
}

.max-h-l {
    max-height: 248px;
}

/* SEMANTICS */

.invert {
    filter: invert();
}

.flex {
    display: flex;
}

.overlay-tools {
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    height: 28px;
    border-radius: 4px;
}

input {
    outline: 0;
    border: none;
    background-color: var(--blue-black-4);
    padding: 12px 16px;
    box-sizing: border-box;
    border-radius: 6px;
    line-height: 18px;
}

input.small {
    padding: 8px 12px;
}

a, a:visited {
    color: var(--ks-blue-dark);
}

.section-label {
    font-weight: 600;
}

.ks-input {
    font-family: 'SF Mono';
    padding: 8px 12px;
}
.ks-select {
    width: initial;
    background-color: var(--ks-divider-light);
    border-radius: 4px;
}

.margin-32 {
    margin: 32px;
}

.min-120 {
    min-width: 120px;
}

.min-160 {
    min-width: 160px;
}

.max-160 {
    max-width: 160px;
}

.round-12 {
    border-radius: 12px;
}

.round-8 {
    border-radius: 8px;
}

.max-640 {
    max-width: 640px;
}

#state-test {
    position: absolute;
    background-color: pink;
    z-index: 4;
}

p.white {
    color: white;
}

.blue-dark {
    color: var(--ks-blue-dark);
}

.pad-top-12 {
    padding-top: 12px;
}

.pad-top-8 {
    padding-top: 8px;
}

.ks-menu-content {
    position: absolute;
    right: 75%;
    box-shadow: var(--ks-box-shadow);
    padding: 12px;
    border: 1px solid var(--ks-divider);
    border-radius: 8px;
    top: 75%;
    z-index: 2;
    background-color: white;
    width: max-content;
    display: flex;
}

.visx-tooltip {
    box-shadow: var(--ks-box-shadow) !important;
    padding: 8px !important;
    border: 1px solid var(--ks-divider-transparent-light) !important;
    border-radius: 6px !important;
    font-family: "Poppins", sans-serif !important;
    z-index: 4 !important;
    background-color: white !important;
    color: black !important;
    width: max-content !important;
    display: flex;
    pointer-events: all !important;
}

.max-content {
    width: max-content;
}

.ks-menu-content.top-right {
    top: initial;
    bottom: 132%;
    right: 0;
    z-index: 10;
} 

.ks-menu-content p {
    display: flex;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.14s ease-in-out;
}

.ks-menu-content p:hover {
    background-color: var(--ks-divider-light);
}

.invert {
    filter: invert();
}

.box-shadow-up {
    box-shadow: var(--ks-box-shadow-up);
}

.flex-2 {
    display: flex;
    flex: 2;
}

.flex-3 {
    display: flex;
    flex: 3;
}

.uppercase {
    text-transform: uppercase;
    letter-spacing: 0.4px;
}
/* Resets */
html, body {
    width: 100vw;
    height: 100vh;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    overflow: hidden;
}

p.secondary {
    color: var(--ks-blue-dark);
}

p.tertiary {
    color: var(--ks-divider-dark);
}

p.secondary.on-brand {
    color: rgb(121, 62, 6);
}

.space-around {
    justify-content: space-around;
}

.border-left {
    border-left: 1px solid var(--ks-divider-light);
}


button {
    background: initial;
    color: initial;
    border: initial;
    box-shadow: initial;
    cursor: pointer;
    min-width: initial;
}
/* Keystone Wrapper */
#keystone {
    display: flex;
    width: 100vw;
    height: 100vh;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    overflow: hidden;
    background: #F5F6FA;
    position: fixed;
}

.overflow-auto {
    overflow: auto;
}

/* Content Wrapper with Flex */
#content-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

/* Menu Styles */
#keystone-menu {
    background-color: transparent;
    height: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    transition: flex-basis 0.3s ease, transform 0.3s ease;
}

#keystone-menu.closed {
    transform: translateX(0%);
    flex-basis: 0;
}

#keystone-menu.open {
    transform: translateX(0);
}

/* Tray Styles */
#keystone-tray {
    background-color: #fff;
    width: 480px;
    position: absolute;
    right: 0;
    height: 100%;
    z-index: 2;
    box-shadow: -12px 0 32px var(--ks-divider-transparent-light);
    transform: translateX(100%);
    transition: transform 0.18s ease-in-out;
}

#keystone-tray.open {
    transform: translateX(0);
}

#keystone-menu:last-of-type {
    display: none;
}

.sf-pro {
    font-family: 'SF Pro';
}


.sf-mono {
    font-family: 'SF Mono';
}

table {
    box-shadow: unset;
    border-radius: unset;
    background-color: unset;
}

.number {
    font-family: 'SF Mono';
}

.text-mono {
    font-family: 'SF Mono';
    text-wrap: wrap;
}

.hero-text {
    font-weight: 600;
}

.pad-b-4 {
    padding-bottom: 12px;
}

.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

.relative {
    position: relative;
}

.ks-button {
    font-weight: 500;
    border-radius: 4px;
    padding: 4px 8px;
}

.ks-button:hover {
    background: var(--ks-divider);
}

.ks-button.large {
    padding: 8px 24px;
}

.ks-button.white {
    background-color: white;
}

.ks-button.white:hover {
    background: var(--ks-divider-light);
}

.ks-button.brand {
    background-color: var(--ks-brand-color);
}

.ks-description {
    line-height: 20px;
}

.keystone-divider {
    background-color: var(--ks-divider);
    height: 1px;
}

.width-100 {
    width: 100%;
}

.no-pad-top {
    padding-top: 0;
}

.no-pad-bot {
    padding-bottom: 0;
}

.no-pad-left {
    padding-left: 0;
}

.no-pad-right {
    padding-right: 0;
}

.pad-right-24 {
    padding-right: 24px;
}

.pad-left-24 {
    padding-left: 24px;
}


.pad-right-16 {
    padding-right: 16px;
}

.keystone-notification-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--ks-brand-color);
    position: absolute;
    right: 16px;
}

.desktop-hide {
    display: none !important;
}

.width-50 {
    flex-basis: 20%;
    flex-grow: 1;
}

.flex-grow {
    flex-grow: 1;
}

.flex-container {
    display: flex;
    flex-direction: row; /* use column if that's what's needed for vertical layout */
    align-items: stretch; /* ensures children stretch to fill container height */
    justify-content: space-between;
}

.brand-color {
    color: var(--ks-brand-color);
}

.ks-button.b-size-xl {
    padding: 12px 18px;
}

.ks-button.b-size-l {
    padding: 8px 16px;
}

.wordmark-image {
    width: 164px;
    object-fit: contain;
    cursor: pointer;
    transition: all 0.04s ease-in-out;
    position: absolute;
    z-index: 3;
    top: 16px;
    left: 20px;
}

#wordmark-wrapper {
    position: absolute;
    z-index: 3;
    top: 16px;
    left: 20px;
}

.wordmark-inline {
    width: 164px;
    object-fit: contain;
    cursor: pointer;
    transition: all 0.04s ease-in-out;
}

.round-s {
    border-radius: 4px;
}
  
.round-m {
    border-radius: 8px;
}

@media only screen and (max-width: 767px) {
    .round-s {
        border-radius: 2px;
    }
      
    .round-m {
        border-radius: 6px;
    }

    #content-wrapper {
        flex-direction: column-reverse;
    }

    #keystone-menu.closed {
        transform: translateX(0);
    }
    
    #keystone-menu.open {
        transform: translateX(0);
        flex-basis: 0;
    }

    #keystone-menu:last-of-type {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 4px;
    }

    .max-640 {
        max-width: 100%;
    }

    .min-120 {
        min-width: 80px;
    }
    
    .min-160 {
        min-width: 120px;
    }
    
    .max-160 {
        max-width: 120px;
    }

    .mobile-vertical {
        flex-direction: column;
    }

    .mobile-hide {
        display: none !important;
    }
    
    .desktop-hide {
        display: flex !important;
    }
    
    #keystone-tray {
        width: 100%;
        position: absolute;
        right: 0;
        height: 100%;
        z-index: 2;
        box-shadow: -12px 0 32px #E6E8F0;
        transform: translateY(100%);
        transition: transform 0.3s ease;
    }
    
    #keystone-tray.open {
        transform: translateY(0);
    }

    .size-xs {
        font-size: 8px;
        line-height: 10px;
    }
    
    .size-s {
        font-size: 10px;
        line-height: 12px;
    }
    
    .size-m {
        font-size: 12px;
        line-height: 14px;
    }
    
    .size-l {
        font-size: 14px;
        line-height: 16px;
    }
    
    .size-xl {
        font-size: 16px;
        line-height: 18px;
    }

    .size-display {
        font-size: 20px;
        line-height: 22px;
    }

    .pad-xxs {
        padding: 2px;
    }
    .pad-b-xxs {
        padding-bottom: 2px;
    }
    .pad-l-xss {
        padding-left: 2px;
    }
    .pad-r-xss {
        padding-right: 2px;
    }
    .pad-t-xss {
        padding-top: 2px;
    }

    .pad-xs {
        padding: 4px;
    }
    .pad-b-xs {
        padding-bottom: 4px;
    }
    .pad-l-xs {
        padding-left: 4px;
    }
    .pad-r-xs {
        padding-right: 4px;
    }
    .pad-t-xs {
        padding-top: 4px;
    }

    
    .pad-s {
        padding: 6px;
    }
    .pad-b-s {
        padding-bottom: 6px;
    }
    .pad-l-s {
        padding-left: 6px;
    }
    .pad-r-s {
        padding-right: 6px;
    }
    .pad-t-s {
        padding-top: 6px;
    }

    
    .pad-m {
        padding: 12px;
    }
    .pad-b-m {
        padding-bottom: 12px;
    }
    .pad-l-m {
        padding-left: 12px;
    }
    .pad-r-m {
        padding-right: 12px;
    }
    .pad-t-m {
        padding-top: 12px;
    }
        
    
    .pad-l {
        padding: 16px;
    }
    .pad-b-l {
        padding-bottom: 16px;
    }
    .pad-l-l {
        padding-left: 16px;
    }
    .pad-r-l {
        padding-right: 16px;
    }
    .pad-t-l {
        padding-top: 16px;
    }
        
    .pad-xl {
        padding: 20px;
    }
    .pad-b-xl {
        padding-bottom: 20px;
    }
    .pad-l-xl {
        padding-left: 20px;
    }
    .pad-r-xl {
        padding-right: 20px;
    }
    .pad-t-xl {
        padding-top: 20px;
    }

    
    .pad-h-xs {
        padding-left: 4px;
        padding-right: 4px;
    }
    .pad-v-xs {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    
    .pad-h-s {
        padding-left: 6px;
        padding-right: 6px;
    }
    .pad-v-s {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    
    .pad-h-m {
        padding-left: 12px;
        padding-right: 12px;
    }
    .pad-v-m {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    
    .pad-h-l {
        padding-left: 16px;
        padding-right: 16px;
    }
    .pad-v-l {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    
    .pad-h-xl {
        padding-left: 20px;
        padding-right: 20px;
    }
    .pad-v-xl {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .v-gap-xxs {
        row-gap: 0px;
    }
    
    .v-gap-xs {
        row-gap: 2px;
    }
    
    .v-gap-s {
        row-gap: 6px;
    }
    
    .v-gap-m {
        row-gap: 8px;
    }
    
    .v-gap-l {
        row-gap: 12px;
    }
    
    .v-gap-xl {
        row-gap: 16px;
    }
    
    .v-gap-xxl {
        row-gap: 20px;
    }
    
    .v-gap-display {
        row-gap: 28px;
    }

    .h-gap-xxs {
        column-gap: 0px;
    }
    
    .h-gap-xs {
        column-gap: 2px;
    }
    
    .h-gap-s {
        column-gap: 4px;
    }
    
    .h-gap-m {
        column-gap: 6px;
    }
    
    .h-gap-l {
        column-gap: 8px;
    }
    
    .h-gap-xl {
        column-gap: 12px;
    }
    
    .h-gap-xxl {
        column-gap: 16px;
    }
    
    .h-gap-display {
        column-gap: 24px;
    }

    .width-50 {
        flex-basis: 40%;
        flex-grow: 1;
    }

    .no-pad-top {
        padding-top: 0;
    }
    
    .no-pad-bot {
        padding-bottom: 0;
    }
}

.text-link {
    text-decoration: underline;
    cursor: pointer;
}