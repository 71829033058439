#keystone-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(12px);
    z-index: 999;
    pointer-events: none;
    transition: all 0.1s ease-in-out;
}

#keystone-loader.shown {
   pointer-events: all;
   opacity: 1;
}

#keystone-loader.hidden {
   pointer-events: none;
   opacity: 0;
}

#lazy-loader {
   position: absolute;
}

.keystone-loader {
   width: 48px;
   height: 48px;
   animation: spinner-y0fdc1 2.4s infinite ease;
   transform-style: preserve-3d;
   rotate: 180deg;
}

.keystone-loader > div {
   background-image: linear-gradient(45deg, #00000020, #00000000);
   height: 100%;
   position: absolute;
   width: 100%;
   border-radius: 28%;
   backdrop-filter: blur(1px);
   border: 4px solid transparent;
   box-sizing: border-box;
   animation: adapt-bg 2.4s infinite ease;
}

.keystone-loader div:nth-of-type(1) {
   transform: translateZ(-24px) rotateY(180deg);
}

.keystone-loader div:nth-of-type(2) {
   transform: rotateY(-270deg) translateX(24px);
   transform-origin: top right;
}

.keystone-loader div:nth-of-type(3) {
   transform: rotateY(270deg) translateX(-24px);
   transform-origin: center left;
}

.keystone-loader div:nth-of-type(4) {
   transform: rotateX(90deg) translateY(-24px);
   transform-origin: top center;
}

.keystone-loader div:nth-of-type(5) {
   transform: rotateX(-90deg) translateY(24px);
   transform-origin: bottom center;
}

.keystone-loader div:nth-of-type(6) {
   transform: translateZ(20px);
   background-image: linear-gradient(135deg, #ffffff, #FFB11C);
   border-color: black;
   animation: slideout 2.4s infinite ease;
}

#loader-label {
   position: absolute;
   padding-top: 154px;
}

@keyframes spinner-y0fdc1 {
   0% {
      transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
   }

   50% {
      transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
   }

   100% {
      transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
   }
}

@keyframes slideout {
   0% {
      rotate: 0deg;
   }

   25% {
      rotate: 0deg;
   }

   75% {
      rotate: 45deg;
      transform: translateZ(48px);
   }

   100% {
      rotate: 0deg;
   }
}

@keyframes adapt-bg {
   0% {

   }

   25% {

   }

   75% {

   }

   100% {

   }
}
    
    
@media only screen and (max-width: 767px) {
}