#keystone-content {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    flex: 1;
    background-color: white;
    box-sizing: border-box;
    margin: 0 12px 12px 16px;
    box-shadow: 0 12px 32px #E6E8F0;
    border-radius: 8px;
    transition: all 0.3s ease;
}

h4 {
    margin: 0;
}

#content-header {
    padding: 16px 16px 16px 20px;
    margin: 12px 12px 12px 16px;
    display: flex;
    background-color: white;
    border-radius: 8px;
    box-shadow: var(--ks-box-shadow);
}

#content-body {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 0 32px;
    box-sizing: border-box;
    border-radius: 24px;
    flex-direction: column;
}

.upload-section {
    background-image: radial-gradient(circle, #E6E8F0 2px, transparent 2px), radial-gradient(circle, #E6E8F0 2px, transparent 2px), radial-gradient(circle, #E6E8F0 2px, transparent 2px), radial-gradient(circle, #E6E8F0 2px, transparent 2px);
    background-position: left top, right top, left bottom, left top;
    background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
    background-size: 12px 4px, 4px 12px, 12px 4px, 4px 12px;
}

#content-footer {
    display: flex;
    justify-content: space-around;
    margin: 32px;
    box-sizing: border-box;
    border: 1px solid #E6E8F0;
    border-radius: 12px;
    padding: 14px;
}

.info-block {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.info-block h4 {
    margin: 0;
}

.info-block.outer-divider {
    border-right: 1px solid #E6E8F0;
    border-left: 1px solid #E6E8F0;
    margin: 0 24px;
    padding: 0 24px;
}

#inventory-placeholder {
    border-radius: 100%;
}



#file-upload {
    display: none;
}

#content-body.product-example {
    border-radius: 0;
    display: flex;
    align-items: flex-start;
    row-gap: 12px;
    overflow-y: scroll;
    justify-content: flex-start;
    /* show scrollbar */
    scrollbar-width: thin;
    scrollbar-color: #E6E8F0 #f6f6f6;
    padding-top: 12px;
}

.product-description {
    font-family: 'SF Pro';
}
#product-documents {
    display: flex;
    column-gap: 8px;
    row-gap: 8px;
    flex-wrap: wrap;
}

.specification-group {
    display: block;
}

.product-manufacturer {
    font-weight: 600;
}

.product-manufacturer a {
    color: black;
    text-decoration: none;
}

.product-horizontal-container {
    display: flex;
    min-width: 0;
}

.total-cost {
    font-family: 'SF Mono';
    line-height: 24px;
}
.product-vertical-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-width: 0;
}

.product-vertical-container.reverse-column {
    flex-direction: column-reverse;
}

.product-image-wrapper {
    display: flex;
    flex: 1;
    padding: 32px;
}

.product-image {
    width: 100%;
    object-fit: contain;
    background-color: white;
    box-sizing: border-box;
    border-radius: 8px;
}

.symbol-image {
    width: 100%;
    object-fit: contain;
    background-color: white;
    box-sizing: border-box;
    border-radius: 8px;
}

#product-example-header {
    border-radius: 16px;
    width: 100%;
}

#product-example-header p {

}


#product-example-content {

}

.product-price, .product-quantity {
    font-family: 'SF Mono';
}

#product-market-data {
    display: flex;
    row-gap: 4px;
    border: 1px solid #E6E8F0;
    padding: 12px;
    border-radius: 6px;
}

#product-internal-data {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    border: 1px solid #E6E8F0;
    padding: 12px;
    border-radius: 6px;
}



input.simple {
    border: none;
    background: #E6E8F0;
    border-radius: 4px;
    padding: 8px 4px;
    text-align: center;
}

.v-pad-16 {
    padding: 16px 0;
}

.flex-1 {
    display: flex;
    flex: 1;
}

.align-center {
    align-items: center;
}

.center-text {
    text-align: center;
}

.product-horizontal-container .keystone-statistic.horizontal:first-child .keystone-divider:first-of-type, .product-horizontal-container .keystone-statistic.horizontal:last-child .keystone-divider:last-of-type {
    opacity: 0;
}

.statistic-wrapper .keystone-statistic:first-of-type .keystone-divider:first-of-type, .statistic-wrapper .keystone-statistic:last-of-type .keystone-divider:last-of-type {
    opacity: 0;
}

.img-16 {
    width: 16px;
    height: 16px;
    border-radius: 4px;
}

.img-24 {
    width: 24px;
    height: 24px;
}


.img-32 {
    width: 32px;
    height: 32px;
}

.img-48 {
    width: 48px;
    height: 48px;
}

#product-lottery {
    display: flex;
    column-gap: 12px;
    flex-wrap: wrap;
    row-gap: 12px;
}

.suggested {
    border: 2px solid black;
    padding: 8px;
    border-radius: 6px;
}

.unsuggested {
    border: 2px solid #fff;
    padding: 8px;
    border-radius: 6px;
}

#strategy-suggestion { 
    border-top: 1px solid var(--ks-divider);
    position: relative;
    border-radius: 0px;
    padding: 24px;
}

#strategy-suggestion-label {
    position: absolute;
    top: -1px;
    left: 16px;
    font-weight: 600;
    background-color: var(--ks-divider);
    border: 1px solid var(--ks-divider);
    padding: 4px 8px;
    font-family: 'SF Mono';
    text-transform: uppercase;
    width: fit-content;
    border-radius: 4px;
}

.strategy-label {
    line-height: 20px;
    font-family: "SF Pro";
}

.percent-tag {
    display: flex;
    color: rgb(38, 92, 38);
    background-color: rgb(196, 236, 196);
    width: fit-content;
    font-family: "SF Mono";
    padding: 4px 6px;
    font-weight: bold;
    border-radius: 12px;
    margin-top: 4px;
}

#inventory-upload-cta {
    width: 100%;
    border: 2px solid #FFB11C;
    padding: 24px;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    margin-top: 16px;
}

#inventory-upload-cta #strategy-suggestion-label {
    color: #e19200;
}

.keystone-primary-button {
    background: #FFB11C;
    border-radius: 6px;
    font-family: 'SF Pro';
    font-weight: 400;
    cursor: pointer;
}

.keystone-primary-button:hover {
    background: #ffb937;
}

.img-12 {
    width: 12px;
    height: 12px;
}

.keystone-card {
    border: 1px solid var(--ks-divider-transparent-light);
    border-radius: 6px;
    box-shadow: var(--ks-box-shadow-shallow);
    background: white;
    position: relative;
    box-sizing: border-box;
}

.keystone-card-secondary {
    border-radius: 8px;
    background: var(--ks-divider-superlight);
    position: relative;
    box-sizing: border-box;
}

.keystone-card.selected {
    border: 1px solid var(--ks-divider-dark);
}

.keystone-card-footer {
    border-top: 1px solid var(--ks-divider-light);
    padding: 24px;
    box-sizing: border-box;
}

#linked-part {
    border: 1px solid var(--ks-divider);
    height: 48px;
    width: 48px;
    position: absolute;
    left: calc(50% - 24px);
    background-color: white;
    border-radius: 100%;
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--ks-box-shadow-shallow);
    z-index: 2;
    top: calc(50% - 24px);
}

.space-around {
    justify-content: space-around;
}

.pad-bot-none {
    padding-bottom: 0;
}

#content-tab {
    padding: 2px;
}

@media only screen and (max-width: 767px) {
    #keystone-content {
        margin: 0;
        border-radius: 0;
    }

    #content-header {
        padding: 8px;
        margin: 0;
        flex-direction: column;
    }

    #content-tab {
        width: 100%;
    }

    .keystone-card {
        border: none;
        border-bottom: 1px solid var(--ks-divider);
        border-radius: 0px;
        box-shadow: var(--ks-box-shadow-shallow);
        background: white;
        position: relative;
        box-sizing: border-box;
    }

    #inventory-search-wrapper {
        width: 100%;
    }
}