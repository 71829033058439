#keystone-terms-agreement {
   flex: 1;
   max-width: 60%;
}
    
#keystone-terms-agreement iframe {
    border: none;
    border-radius: 8px;
    min-height: 640px;
    border: 1px solid var(--ks-divider);
    box-sizing: border-box;
}
@media only screen and (max-width: 767px) {
    #keystone-terms-agreement {
        height: -webkit-fill-available;
    }

    #keystone-terms-agreement iframe {
        min-height: initial;
    }
}