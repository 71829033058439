.keystone-tab {
   
}

.ks-button.tab {
    padding: 6px 12px;
    min-width: 64px;
    width: max-content;
}

.ks-button.tab.active {
    background-color: var(--ks-divider);
}
    
@media only screen and (max-width: 767px) {
    .keystone-tab, .ks-button.tab {
        flex: 1;
        width: 100%;
    }
}