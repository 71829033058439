.keystone-simple-loader {
   border-radius: 50%;
   border: 4px solid transparent;
   border-top-color: white;
   border-left-color: white;
   animation: spin-pulse 1.48s ease-in-out infinite;
   width: 100%;
   height: 100%;
}

@keyframes spin-pulse {
   0% {
      transform: rotate(0deg);
      opacity: 1;
   }
   25% {
      transform: rotate(90deg);
      opacity: 0.9;
   }
   50% {
      transform: rotate(180deg);
      opacity: 0.8;
   }
   75% {
      transform: rotate(270deg);
      opacity: 0.9;
   }
   100% {
      transform: rotate(360deg);
      opacity: 1;
   }
}

.keystone-simple-loader-wrapper {
   position: absolute;
   z-index: 2;
   background-color: #00000040;
   border-radius: 100%;
   backdrop-filter: blur(4px);
}

#keystone-modal .keystone-simple-loader-wrapper {
   z-index: 4;
}

@media only screen and (max-width: 767px) {
}