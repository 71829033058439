.keystone-header-visual {
    background: #ffffff;
    width: fit-content;
    border: 1px solid var(--ks-divider-transparent);
    display: flex;
    align-items: center;
    padding: 4px 6px;
    position: relative;
    border-radius: 2px;
    flex: 1;
}

.keystone-header-visual.regular {
    padding: 0px;
    border-radius: 100%;
    border: none;
    position: relative;
    top: -1px;
}

.keystone-header-visual-label {
    font-family: "SF Mono";
    text-transform: uppercase;
    font-weight: bold;
    color: var(--ks-blue-dark);
    letter-spacing: 0.4px;
    line-height: 12px;
}

.keystone-header-visual-label.light {
    font-weight: normal;
    text-transform: none;
    letter-spacing: 0;
}

.keystone-header-visual img {
    width: 12px;
    height: 12px;
}

.header-visual-horizontal {
    padding: 4px;
    border-top: 1px solid var(--ks-divider-transparent);
    border-bottom: 1px solid var(--ks-divider-transparent);
}

.header-visual-vertical {
    padding: 4px;
    box-sizing: border-box;
    border-left: 1px solid var(--ks-divider-transparent);
    border-right: 1px solid var(--ks-divider-transparent);
    position: relative;
    margin: 0 8px;
}

.fit-content {
    width: fit-content;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-center {
    justify-content: center;
}

.modal-disclaimer {
    margin-top: 12px;
    color: #50577a;
}

.header-visual-tooltip {
    position: absolute;
    background: white;
    padding: 8px;
    box-shadow: var(--ks-box-shadow);
    left: 120%;
    top: 120%;
    border-radius: 4px;
    width: fit-content;
    border: 1px solid #cccfdd;
    line-height: 16px;
    z-index: 300;
    min-width: 200px;
}

.modal-tooltip {
    position: fixed;
    background: white;
    padding: 8px;
    box-shadow: var(--ks-box-shadow);
    border-radius: 4px;
    left: inherit;
    top: inherit;
    width: 15%;
    border: 1px solid #cccfdd;
    line-height: 16px;
    z-index: 300;
    min-width: 200px;
}

.header-visual-select-wrapper {
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0 1px 2px #E6E8F0;
    border-radius: 4px;
    border: 1px solid #cdd0da;
    transition: all 0.1s ease-in-out;
}

.header-visual-select {
    font-family: 'SF Mono';
    text-transform: uppercase;
    font-weight: bold;
    color: black;
    letter-spacing: normal;
    line-height: 14px;
    font-size: 12px;
    padding: 4px 6px;
}

.header-visual-select.none-selected {
    color: var(--ks-blue-dark);
}

.header-visual-select option {
    line-height: 16px;
}

.header-visual-select-wrapper:hover {
    box-shadow: 0 2px 8px #E6E8F0;
}

.header-visual-arrow img {

}

.header-visual-arrow .vertical-divider {
    height: 12px;
    width: 2px;
    background-color: #ebecf1;
}

.header-visual-arrow .horizontal-divider {
    height: 2px;
    width: 12px;
    background-color: #ebecf1;
}

.header-visual.negative .keystone-label {
    color: #ba0e0e;
}

.header-visual.updated .keystone-label {
    color: #d57500;
}

.header-visual.positive .vertical-divider {
    background-color: #0e9c0e;
}

.header-visual.positive .header-visual-arrow img, .header-visual.updated .header-visual-arrow img, .header-visual.negative .header-visual-arrow img {
    filter: invert();
}

.header-visual.positive .image-wrapper {
    background-color: #0e9c0e;
}

.header-visual.negative .image-wrapper {
    background-color: #ba0e0e;
}   

.header-visual .image-wrapper {
    display: flex;
    border-radius: 100%;
    background-color: #ebecf1;
    padding: 2px;
}

/* .header-visual.positive .keystone-header-visual {
    border-color: #0e9c0e;
} */

.header-visual.updated .image-wrapper {
    background-color: #dd8f30;
}   

.header-visual.updated .keystone-header-visual {
    border-color: #dd8f30;
}

.header-visual.negative .keystone-header-visual {
    border-color: #ba0e0e80;
}

.header-visual.updated .vertical-divider {
    background-color: #dd8f30;
}

@media only screen and (max-width: 767px) {
    .keystone-header-visual {
        width: fit-content;
        display: flex;
        align-items: center;
        padding: 4px 6px;
        position: relative;
        border-radius: 2px;
        flex: 1;
    }
}