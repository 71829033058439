.keystone-action {
    border-radius: 4px;

}

.keystone-action p {
    display: flex;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.14s ease-in-out;
}

.keystone-action p:hover {
    background-color: var(--ks-divider-light);
}

.keystone-action:hover {
    background-color: var(--ks-divider-light);
}

.keystone-action.negative {
    color: var(--ks-negative-text);
}

.keystone-action.positive {
    color: var(--ks-positive-text);
}

@media only screen and (max-width: 767px) {
}