.keystone-toast {
    background: #00000060;
    backdrop-filter: blur(4px);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    opacity: 0;
    transform: translateY(-20px);
    animation: slideIn 0.5s forwards, ease-in-out 0.5s forwards 1s;
}

.keystone-toast.negative {
    background: var(--ks-negative);
}

.keystone-toast.positive {
    background: var(--ks-positive);
}

.keystone-toast.user {
    border-radius: 8px;
    padding: 16px 20px 16px 16px;
}

.keystone-toast.fade-out {
    animation: fadeOut 0.2s forwards;
}

.keystone-toast.invisible {
    opacity: 0;
    transform: translateY(-20px);
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
    
@media only screen and (max-width: 767px) {
}