.keystone-divider {
    align-items: center;
    justify-content: center;
    display: flex;
}
.keystone-divider p {
    background-color: white;
    padding: 0 12px;
    box-sizing: border-box;
    display: inline-block;
}

.keystone-divider.transparent p {
    background-color: transparent;
}

@media only screen and (max-width: 767px) {
}