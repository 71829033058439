.keystone-offers {
   position: relative;
   display: flex;
   flex: 1;
   box-sizing: border-box;
}

#keystone-offers-estimates {
   background-color: var(--ks-brand-color);
   padding: 24px;
   border-radius: 12px;
}

#keystone-offers-estimates .title {
   font-weight: 600;
}

.keystone-offers-container {
   background-color: white;
   border-radius: 8px;
   box-shadow: var(--ks-box-shadow-shallow);
   padding: 24px;
   box-sizing: border-box;
}

.offers-promo {
   background: var(--ks-brand-color);
   padding: 16px;
   border-radius: 6px;
}

@media only screen and (max-width: 767px) {
}