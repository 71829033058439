.keystone-linked-card {
}

.linked-card-icon-wrapper {
    padding: 4px;
    background-color: white;
    box-shadow: var(--ks-box-shadow-shallow);
    display: flex;
    border: 1px solid var(--ks-divider);
    border-radius: 100%;
    position: relative;
    z-index: 2;
}

@media only screen and (max-width: 767px) {
}