#login {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-image: url('../../media/tile-bg.png');
    background-repeat: repeat;
    background-size: 992px 992px;
    overflow: hidden;
    background-color: white;
    /* background-color: linear-gradient(to bottom, rgba(246, 246, 246, 0) 0%, rgba(246, 246, 246, 0) 100%); */
}

#login::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgba(246, 246, 246, 0) 10%, rgba(246, 246, 246, 1) 100%);
    pointer-events: none;  /* Make sure this overlay doesn't affect interaction with the underlying content */
    z-index: 1;
}

#login-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
}

#login-content-wrapper {
    align-items: left;
    border-radius: 12px;
    box-shadow: 0 12px 48px rgba(0,0,0,0.08);
    overflow: hidden;
    max-width: 420px;
    margin-top: 16px;
    box-sizing: border-box;
}

.login-header-footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    z-index: 2;
}
  
#splash-footer p {    
    margin: 0;
}

#login-privacy-anchor {
    margin-top: 16px;
    font-weight: 500;
}

#login-details-wrapper {
    background-color: white;
}

#login-google-button {
    display: flex;
    background: white;
    border: 1px solid rgba(0,0,0,0.16);
    height: 42px;
    border-radius: 6px;
    color: black;
    align-items: center;
    padding: 0 14px;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0 4px 8px rgba(0,0,0,0.04);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

#login-google-button:hover {
    box-shadow: 0 6px 12px rgba(0,0,0,0.08);
    position: relative;
}

#login-google-button img:first-child, #login-google-button img:last-child {
    width: 24px;
}

#login-google-button img:last-child {
    opacity: 0;
}

#login-header-wrapper {
    /* background: linear-gradient(25deg, black 0%, rgba(0, 0, 0, 0.0) 100%); */
    padding-top: 64px;
}

#login-footer-wrapper {
    backdrop-filter: blur(4px);
    padding: 20px 16px;
    border-top: 1px solid rgba(0,0,0,0.08);
    text-align: center;
}

#login-footer-wrapper a, #login-footer-wrapper a:visited {
    color: black;
}

#login-footer-wrapper.cta {
    background: #FFCC16;
}

#login-footer-wrapper.cta p {
    color: black;
}

@media only screen and (max-width: 767px) {
    #login-content-wrapper {
        max-width: 92%;
    }
}