.keystone-offer-details {
    max-height: -webkit-fill-available;
   
} 

.keystone-shipment-container {
    background-color: var(--ks-blue);
    border-radius: 8px;
}
    
.bank-info {
    border-radius: 8px;
    border: 1px solid var(--ks-divider);
}

@media only screen and (max-width: 767px) {
}